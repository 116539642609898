<template>
  <v-app
    id="inspire"
    style="background: rgb(195,77,34);
    background: linear-gradient(0deg, rgba(195,77,34,1) 0%, rgba(253,156,45,1) 100%);"
  >
    <v-layout justify-center>
      <v-card
        style="margin:auto; border-radius: 20px"
        :min-width="$vuetify.breakpoint.mdAndUp ? '380px' : '330px'"
        min-height="583px"
      >
        <div>
          <v-img
            :src="require('@/assets/login-vertical-gris.png')"
            height="125px"
            contain
            style="border: 25px solid transparent;"
            class="mt-2"
          />
        </div>
        <div>
          <v-layout justify-center>
            <v-icon
              v-if="notify_email"
              size="50px"
              color="black"
            >
              fal fa-envelope-open-text
            </v-icon>
            <v-icon
              v-else
              size="50px"
              color="black"
            >
              fal fa-lock-alt
            </v-icon>
          </v-layout>
        </div>
        <v-card-text>
          <v-flex
            xs12
          >
            <p
              align="center"
              style="font-size: 19px; font-weight: bold; color:#424243;"
            >
              ¿Olvidaste tu contraseña?
            </p>
            <v-layout justify-center>
              <p
                align="justify"
                style="font-size: 12px;"
                class="blue-grey--text text--darken-1"
              >
                Ingresa tú email de regístro para reestablecer tu <br>
                contraseña. Recibirás un email con instrucciones para <br>
                restablecer la contraseña. Si tienes problemas para <br>
                restablecer tu contraseña, contactanos o envíanos <br>
                un email.
              </p>
            </v-layout>
            <div
              v-if="!notify_email"
              style="margin:0px 40px 0px 0px;"
            >
              <v-text-field
                ref="email"
                v-model="email"
                full-width
                type="text"
                autofocus="autofocus"
                maxlength="150"
                prepend-icon="fal fa-user"
                name="login"
                label="E-mail"
                :error-messages="userErrors"
                box
              />
              <v-layout justify-center>
                <v-btn
                  :disabled="btn_disabled"
                  color="info2"
                  type="submit"
                  class="white--text text-none"
                  style="width: 90%; font-size: 15px; margin: 0px 0px 0px 34px;"
                  @click.prevent="submit"
                >
                  Reestablece tu contraseña
                </v-btn>
              </v-layout>
            </div>
          </v-flex>
        </v-card-text>
        <template v-if="notify_email">
          <v-alert
            v-model="notify_email"
            color="#F39C12"
            text-color="white"
            style="height: 60px; width: 286px; margin-bottom: 105px"
          >
            <v-layout
              justify-center
            >
              <v-icon
                class="mr-3 mb-2"
                color="white"
              >
                fal fa-check
              </v-icon>
              <p
                class="mb-2"
                style="font-size: 12px; color:white;"
              >
                Revisa tu correo y sigue las instrucciones para reestablecer tu contraseña.
              </p>
            </v-layout>
          </v-alert>
        </template>
        <v-card-actions class="mt-5">
          <v-layout
            row
            align-end
            justify-space-around
          >
            <p
              style="font-size: 14px; color:#01ACC1; font-weight: bold; cursor: pointer"
              @click="login_dialog = true"
            >
              Inicia sesión
            </p>
            <p
              style="font-size: 14px; color:#01ACC1; font-weight: bold; cursor: pointer"
              @click="signup_modal = true"
            >
              ¿No tienes cuenta? Regístrate
            </p>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-layout>
    <v-dialog
      v-model="signup_modal"
      max-width="432"
      persistent
    >
      <CandidateSignup
        @signupDone="finishSignup"
        @closeModal="signup_modal = false; login_dialog = true"
      />
    </v-dialog>
    <v-dialog
      v-model="login_dialog"
      width="400"
    >
      <LoginCard @loginSuccess="loginSuccess" />
    </v-dialog>
    <LoadingComponent />
  </v-app>
</template>

<script>
import axios from 'axios';
import { parseJwt } from '@/utils/jwt';
import LoadingComponent from '@/components/misc/LoadingComponent.vue';
import CandidateSignup from '@/components/forms/CandidateSignup.vue';
import LoginCard from '@/components/forms/LoginCard.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'ForgetPassword',
  components: {
    LoadingComponent,
    CandidateSignup,
    LoginCard,
  },
  mixins: [JWTConfigMixin],
  data() {
    return {
      login_dialog: false,
      signup_modal: false,
      btn_disabled: false,
      notify_email: false,
      email: '',
      userErrors: [],
    };
  },

  methods: {
    loginSuccess() {
      this.$router.push('/');
    },
    submit() {
      this.$store.commit('setLoading');
      this.btn_disabled = true;
      axios.post(this.$store.state.endpoints.passwordReset, { email: this.email })
        .then(resp => {
          this.$store.commit('unsetLoading');
          this.notify_email = true;
          console.log(resp);
        })
        .catch(error => {
          this.userErrors = error.response.data.email;
          this.$store.commit('unsetLoading');
          this.btn_disabled = false;
        });
    },
    finishSignup(payload) {
      this.signup_modal = false;
      this.username = payload.username;
      this.password = payload.password;
      this.$emit('signupDone', '');
      this.authenticate();
    },
    authenticate() {
      this.$store.commit('setLoading');
      this.errorchip = false;
      this.passErrors = [];
      this.userErrors = [];

      const payload = {
        username: this.username,
        password: this.password,
      };

      axios.post(this.$store.state.endpoints.obtainJWT, payload)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', parseJwt(this.$store.state.jwt).is_admin);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');
                this.$emit('loginSuccess', '');
                this.$router.push('/');
              });
          } else {
            this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
            this.$store.commit('unsetLoading');
            this.$emit('loginSuccess', '');
          }
        })
        .catch(error => {
          this.$store.commit('unsetLoading');
          if (error.response.data.non_field_errors) {
            this.errorchip = true;
            this.chipMsg = error.response.data.non_field_errors.pop();
          } else {
            if (error.response.data.username) {
              this.userErrors = [...error.response.data.username];
            }
            if (error.response.data.password) {
              this.passErrors = [...error.response.data.password];
            }
          }
        });
    },
  },
};
</script>
